import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class BancoService {
  async getBancosAll() {
    const banco = await fetchWrapper.get(`${ruta}/banco`);
    return banco;
  }

  async nuevoBanco(banco) {
    const response = await fetchWrapper.post(`${ruta}/banco`, banco);
    return response;
  }

  async editarBanco(banco) {
    const response = await fetchWrapper.put(`${ruta}/banco/` + banco.id, banco);
    return response;
  }

  async eliminarBanco(id) {
    const response = await fetchWrapper.delete(`${ruta}/banco/` + id);
    return response;
  }

  async nuevoMovBanco(mov) {
    const response = await fetchWrapper.post(`${ruta}/banco/movbanco`, mov);
    return response;
  }

  async buscarMovBancos(datos) {
    const response = await fetchWrapper.post(
      `${ruta}/banco/movbanco/buscar`,
      datos
    );
    return response;
  }
}